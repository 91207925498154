<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Kegiatan</span>
      </template>

      <template>
        <b-card>
          <!-- form -->
          <b-form class="mt-2">
            <b-row>
              <b-col sm="12">
                <b-form-group label="Nama Lengkap">
                  <b-form-input
                    v-model="localData.name"
                    placeholder="Nama Lengkap"
                    name="namalengkap"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Penyelenggara" label-for="penyelenggara">
                  <b-form-input
                    v-model="localData.penyelenggara"
                    name="penyelenggara"
                    placeholder="Penyelenggara"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Kegiatan" label-for="kegiatan">
                  <b-form-input
                    v-model="localData.kegiatan"
                    name="kegiatan"
                    placeholder="Kegiatan"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Lokasi" label-for="lokasi">
                  <b-form-input
                    v-model="localData.lokasi"
                    name="lokasi"
                    placeholder="Lokasi"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Tanggal Mulai" label-for="start_date">
                  <b-form-input
                    v-model="localData.start_date"
                    name="start_date"
                    placeholder="Tanggal Mulai"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Tanggal Selesai" label-for="end_date">
                  <b-form-input
                    v-model="localData.end_date"
                    name="end_date"
                    placeholder="Tanggal Selesai"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Lampiran:">
                  <b-row v-for="(x, y) in lampiran" :key="y" class="pb-2">
                    <b-col sm="11">
                      <b-form-file
                        v-model="x.lampiran"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                        v-if="x.value == ''"
                      />
                      <div v-else>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="success"
                          class="mr-1"
                          @click="download(x.nama_file)"
                        >
                          <b-spinner
                            v-show="btnSimpanSpinnerShow"
                            small
                            type="grow"
                          />
                          {{ x.nama_file }}
                        </b-button>
                        <b-button
                          variant="danger"
                          @click="hapusLampiranDokumen(x.id)"
                          >X</b-button
                        >
                      </div>
                    </b-col>
                    <b-col sm="1" v-if="x.value == ''">
                      <b-button variant="danger" @click="hapusLampiran(y)"
                        >X</b-button
                      >
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="12">
                      <b-button variant="warning" @click="tambahLampiran"
                        >Tambah Lampiran</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="simpan"
                >
                  <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </template>
    </b-tab>
    <!--/ general tab -->
  </b-tabs>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BTabs,
  BTab,
  BFormFile,
} from 'bootstrap-vue'
import { api, upload, download } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BTabs,
    BTab,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {
        biodata_id: null,
        name: null,
        penyelenggara: null,
        kegiatan: null,
        lokasi: null,
        start_date: null,
        end_date: null,
        id: null,
      },
      cle_id: null,
      lampiran: [{ value: '' }],
      isEdit: false,
      isAdmin: false,
      btnSimpanSpinnerShow: false,
      masterUser: null,
      isPilihDPC: false,
      masterStrukturOrganisasi: null,
    }
  },
  computed: {},
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // const userBiodata = await api().get(`probono/biodatabyuser/${userData.id}`)

    const tmpUserId = this.$route.params.id
    if (tmpUserId != null) {
      try {
        this.isEdit = true
        const temp = await api().get(`cle/${tmpUserId}`)
        this.localData.biodata_id = temp.data.biodata[0].id
        this.localData.name = temp.data.biodata[0].nama_lengkap
        this.localData.kegiatan = temp.data.kegiatan
        this.localData.penyelenggara = temp.data.penyelenggara
        this.localData.lokasi = temp.data.lokasi
        this.localData.start_date = temp.data.start_date
        this.localData.end_date = temp.data.end_date
        this.localData.id = temp.data.id
        this.lampiran = temp.data.cle_lampiran
      } catch (error) {
        this.loadingMessage = 'Something went wrong. Please refresh browser'
      }
    } else if (this.$route.params.biodata_id) {
      // TAMBAH BY ADMIN
      this.isAdmin = true
      this.isEdit = false
      const temp = await api().get(`biodata/${this.$route.params.biodata_id}`)
      this.localData.biodata_id = temp.data.id
      this.localData.name = temp.data.nama_lengkap
      // this.localData.created_by = userBiodata.data.biodata[0].id
    } else {
      // TAMBAH MANDIRI
      const temp = await api().get(`probono/biodatabyuser/${userData.id}`)
      this.localData.biodata_id = temp.data.biodata[0].id
      // this.localData.created_by = temp.data.biodata[0].id
      this.localData.name = temp.data.biodata[0].nama_lengkap
    }
  },
  methods: {
    async download(filename) {
      try {
        this.btnDownloadDisabled = true
        const result = await download().get(`cle/download/${filename}`)
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
      } catch (error) {
        alert(error)
      }
    },
    hapusLampiran(index) {
      this.lampiran.splice(index, 1)
    },
    hapusLampiranDokumen(id) {
      this.$bvModal
        .msgBoxConfirm('Apakah anda yakin?', {
          cancelVariant: 'outline-secondary',
          okTitle: 'Delete',
          okVariant: 'danger',
        })
        .then(async value => {
          if (value) {
            await api().delete(`cle/lampiran/${id}`)
            this.makeToast('success', 'Sukses', 'Lampiran berhasil dihapus')
            this.$router.go()
          }
        })
    },
    tambahLampiran() {
      this.lampiran.push({
        value: '',
      })
    },
    async simpan() {
      if (
        !this.localData.name ||
        !this.localData.kegiatan ||
        !this.localData.penyelenggara ||
        !this.localData.lokasi ||
        !this.localData.start_date ||
        !this.localData.end_date
      ) {
        this.makeToast('danger', 'Error', 'Semua Kolom Wajib Diisi', true)
        return
      }

      this.btnSimpanSpinnerShow = true
      try {
        const tempFiles = []
        if (this.lampiran) {
          this.lampiran.forEach(element => {
            const formData = new FormData()
            if (element.lampiran) {
              formData.append('file', element.lampiran)
              tempFiles.push(upload().post('cle/upload', formData))
            }
          })
        }
        if (tempFiles) {
          let promises
          try {
            promises = await Promise.all(tempFiles)
          } catch (error) {
            this.makeToast('danger', 'Error', error, true)
          }
          const tempArray = []
          promises.forEach(element => {
            tempArray.push(element.data.filename)
          })
          this.localData.lampiran_list = tempArray
        }

        let result
        delete this.localData.name
        if (this.isEdit) {
          result = await api().patch('cle', this.localData)
        } else {
          delete this.localData.id
          result = await api().post('cle', this.localData)
        }
        if (result) {
          if (this.isAdmin) {
            this.$router.push('/cle/keanggotaan')
          } else {
            this.$router.push('/cle')
          }
        }
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
        return
      }
      this.makeToast('success', 'Sukses', 'Data berhasil ditambahkan')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
